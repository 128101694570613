import { GetStaticProps } from "next";

import { ChannelTalk } from "@/components/common/features/channel-talk";
import { MainTemplate } from "@/components/template/main";
import { RootLayout } from "@/layout/root-layout";
import { NextPageWithLayout } from "@/pages/_app";
import { getI18nStaticProps } from "@/utils/i18n";

export const getStaticProps: GetStaticProps = getI18nStaticProps;

const MainPage: NextPageWithLayout = () => (
  <>
    <MainTemplate />
  </>
);

export default MainPage;

MainPage.getLayout = (page) => (
  <ChannelTalk>
    <RootLayout>{page}</RootLayout>
  </ChannelTalk>
);
